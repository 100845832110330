html {
  --color-white: #ffffff;
  --color-green: #7BCB55;
  --color-green-dark: #5AA538;
  --color-green-pear: #DCF833;
  --color-red: #FF6B61;
  --color-secondary-red: #EF5350;
  --color-bright-red: #E74C41;
	--color-blue: #4093F4;
  --color-gradus-blue: #0075FF;
	--color-light-blue: rgba(64, 147, 244, 0.04);
  --color-blue-magenta: #6F6CF1;
  --color-blue-secondary: #2863FA;
  --color-dodger-blue: #007AFF;
  --color-cornflower-blue: #6E91F9;
  --color-purpur: #4760E7;
  --color-black: rgba(0, 0, 0, 0.87);
  --color-gray: #949494;
  --color-gray-light: #F6F4F2;
  --color-orange: #F49550;
  --color-orange-bg: rgba(246, 210, 124, 0.1);
  --color-purple: #C859FF;
  --color-purple-bg: rgba(186, 104, 200, 0.1);
  --color-rusty: #ED682E;
  --color-rusty-bg: rgba(237, 104, 46, 0.1);
  --color-paywall-bg: #59309d;
  --color-bg-important-event: #FCF7EF;
	--color-yellow: #FFE500;
  --color-ocher: #E9981E;


  --bg-500: #333333;
	--bg-header: rgba(255, 255, 255, 0.7);
	--bg-header-light: #f6f6f5;

  --main-gradient: linear-gradient(267.5deg, #4760E7 34.89%, #C859FF 87.38%);
  --notifications-background: #ffffff;
  --color-green-border-transparent: rgba(115, 178, 48, 0.1);
  --color-red-border-transparent: rgba(239, 83, 80, 0.1);

  --color-gray-bg-transparent: rgba(0, 0, 0, 0.04);
  --color-green-bg-transparent: rgba(115, 178, 48, 0.1);
  --color-red-bg-transparent: rgba(239, 83, 80, 0.1);
  --color-orange-bg: hsla(42,87%,73%,.1);
  --color-gradus-blue-bg: rgba(71, 96, 231, 0.08);

  --text-primary: rgba(0, 0, 0, 0.87);
  --text-secondary: rgba(0, 0, 0, 0.67);
  --text-third: rgba(0, 0, 0, 0.4);
  --text-disabled: rgba(0, 0, 0, 0.2);

  --text-primary-white: rgba(255, 255, 255, 0.87);
  --text-secondary-white: rgba(255, 255, 255, 0.67);

  --element-border-10: rgba(0, 0, 0, 0.10);

  --popup-background: rgba(245, 245, 245, 1);
  --popup-line-background: rgba(0, 0, 0, 0.1);

  --element-background-white-10: rgba(255, 255, 255, 0.1);

  --paywall-point-background: rgba(217, 217, 217, 0.2);

  --element-background-gray: #F7F7F7;

  --input-background: rgba(0, 0, 0, 0.04);
  --input-background-hex: #f0f0f0;
  --input-background-focus: #FFFFFF;
  --input-border: rgba(0, 0, 0, 0.4);

  --circle-zodiacs-elements-land: #66bb6a;
  --circle-zodiacs-elements-air: #e2eef9;
  --circle-zodiacs-elements-water: #537dcf;
  --circle-zodiacs-elements-fire: #ff8a65;

  --color-sexuality: rgba(248, 152, 116, 1);
  --color-support: rgba(246, 210, 124, 1);
  --color-emotions: rgba(148, 193, 100, 1);
  --color-mentality: rgba(195, 136, 205, 1);
  --color-worth: rgba(112, 174, 246, 1);

  --color-comfort: rgba(64, 147, 244, 0.6);
  --color-situations: rgba(71, 96, 231, 0.6);
  --color-mindset: rgba(239, 83, 80, 0.6);
  --color-actions: rgba(115, 178, 48, 0.6);

  --color-comfort-bg: rgba(64, 147, 244, 0.1);
  --color-situations-bg: rgba(71, 96, 231, 0.1);
  --color-mindset-bg: rgba(239, 83, 80, 0.13);
  --color-actions-bg: rgba(115, 178, 48, 0.1);

  --border: rgba(0, 0, 0, 0.1);
  --border-dark: rgba(0, 0, 0, 0.4);
  --border-light: #F3F3F3;

  --scrollbar-track: rgba(0, 0, 0, 0.04);
  --scrollbar-thumb: rgba(0, 0, 0, 0.4);

	--color-positive: #78C971;
	--color-positive-bg: rgba(120, 201, 113, 0.08);
	--color-negative: #D75443;
	--color-negative-bg: rgba(215, 84, 67, 0.08);

  --shadow: drop-shadow(0px 0.75px 2px rgba(137, 146, 172, 0.12)) drop-shadow(0px 4px 14px rgba(129, 136, 157, 0.08));

	--overlay-bg: rgba(0, 0, 0, 0.1);
	--overlay-bg-37: rgba(29, 29, 29, 0.37);
	--desktop-bg: #f8f8f8;

	/* z-index */
	--z-index-button: 1;
	--z-index-dropdown: 11;
	--z-index-modal: 100;
	--z-index-header: 3;
	--z-index-calendar: 2;
  --z-index-shutter: 80;
  --z-index-overlay: 75;
  --z-index-onboarding-showed-element: 120;

  /* apply and reset button colors */
  --button-apply-color: #5AA538;
  --button-apply-color-bg: rgba(123, 203, 85, 0.2);
  --button-reset-color: #FF6B61;
  --button-reset-color-bg: rgba(255, 107, 97, 0.2);

  /* tips */
  --tip-bg-color: #333333;

  /* error */
  --error-color: #FF6B61;
  --error-color-bg: #FEEEEE;

  /* spheres */
  --sphere-emotions: #4076FF;
  --sphere-communication: #00A0FF;
  --sphere-travel: #31AD87;

  /* Range colors */
  --range-color-pink: #E85176;
  --range-color-blue: #69B5EC;
  --range-color-purple: #C57FF0;
  --range-color-orange: #EDA15C;
  --range-color-green: #77CC62;
}
